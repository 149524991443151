module controllers {
    export module manifest {
        interface IManifestUpdateScope extends ng.IScope {

        }

        interface IManifestUpdateStateParams extends ng.ui.IStateParamsService {
            manId: number;
        }

        interface IManifestCombineScope extends ng.IScope {
            apiManifests: uiGrid.IGridApi;
            gvwManifests: uiGrid.IGridOptions;
            Close(): void;
            Ok(): void;
        }

        interface IExistingConsignmentScope extends ng.IScope {
            apiConsignments: uiGrid.IGridApi;
            gvwConsignments: uiGrid.IGridOptions;
            searchObject: interfaces.applicationcore.ISearchObject;
            pageNumber: number;
            pageCount: number;
            showAll: boolean;
            selectedIds: Array<number>;
            Close(): void;
            Ok(): void;
            checkBoxChanged(): void;
            openEDIPopup(id: number): void;
        }

        export class manifestUpdateCtrl {
            static $inject = ["$scope", '$rootScope', 'generalService', '$q', '$state', '$stateParams', '$timeout', 'manifestService', "bsLoadingOverlayService", "routeCodeService",
                "transportModeService", "countryService", "unLocationCodeService", "warehouseService", "cartageContractorService", "cargoTypeService", "shippingPriorityService", "notifyPartyService",
                "transportTypeService", "routeLeadTimesService", "$anchorScroll", "userAccountService", "$uibModal", "classificationValueService",
                "customsOfficeCodeService", "crewService", "manifestCrewService", "$transitions", "documentRepositoryService", "commercialInvoiceService", "menuService",
                "consignmentService", "sundryItemService", "currencyService", "manifestSundryItemService", "uiGridConstants", "containerService", "customAttributeService", "customAttributeAnswerOptionService", "rejectionService", "containerISOService","manifestTypeService"];
            manifest: interfaces.manifest.IManifest;

            breadCrumbDesc: string;

            countryList: interfaces.applicationcore.IDropdownModel[];
            cartageContractorList: interfaces.applicationcore.IDropdownModel[];
            cargoTypeList: interfaces.applicationcore.IDropdownModel[];
            manifestCustomsTypeList: interfaces.applicationcore.IDropdownModel[];
            loadingOfficeList: interfaces.applicationcore.IDropdownModel[];
            transitOfficeList: interfaces.applicationcore.IDropdownModel[];
            unloadingOfficeList: interfaces.applicationcore.IDropdownModel[];
            totalELC: number;
            totalGLC: number;
            totalALC: number;

            showAll: boolean = false;
            canEDI: boolean = false;
            
            ALCVarianceReasonList: interfaces.applicationcore.IDropdownModel[];
            ALCStatusList: interfaces.applicationcore.IDropdownModel[];
            //Attribute Grid
            apiCA: uiGrid.IGridApi;
            attributeList: interfaces.applicationcore.IDropdownModel[] = [];
            attributeAnswerOptionList: interfaces.applicationcore.IDropdownModel[] = [];

            myHook: any;
            constructor(private $scope: IManifestUpdateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $state: ng.ui.IStateService,
                private $stateParams: IManifestUpdateStateParams,
                private $timeout: ng.ITimeoutService,
                private manifestService: interfaces.manifest.IManifestService,
                private bsLoadingOverlayService,
                private routeCodeService: interfaces.master.IRouteCodeService,
                private transportModeService: interfaces.applicationcore.ITransportModeService,
                private countryService: interfaces.applicationcore.ICountryService,
                private unLocationCodeService: interfaces.applicationcore.IUNLocationCodeService,
                private warehouseService: interfaces.master.IWarehouseService,
                private cartageContractorService: interfaces.master.ICartageContractorService,
                private cargoTypeService: interfaces.master.ICargoTypeService,
                private shippingPriorityService: interfaces.master.IShippingPriorityService,
                private notifyPartyService: interfaces.master.INotifyPartyService,
                private transportTypeService: interfaces.master.ITransportTypeService,
                private routeLeadTimesService: interfaces.master.IRouteLeadTimesService,
                private $anchorScroll: ng.IAnchorScrollService,
                private userAccountService: interfaces.applicationcore.IUserAccountService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private customsOfficeCodeService: interfaces.tariff.ICustomsOfficeCodeService,
                private crewService: interfaces.master.ICrewService,
                private manifestCrewService: interfaces.manifest.IManifestCrewService,
                private $transitions: ng.ui.core.ITransition,
                private documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                private commercialInvoiceService: interfaces.logistics.ICommercialInvoiceService,
                private menuService: interfaces.applicationcore.IMenuService,
                private consignmentService: interfaces.consignment.IConsignmentService,
                private sundryItemService: interfaces.master.ISundryItemService,
                private currencyService: interfaces.applicationcore.ICurrencyService,
                private manifestSundryItemService: interfaces.manifest.IManifestSundryItemService,
                private uiGridConstants: uiGrid.IUiGridConstants,
                private containerService: interfaces.logistics.IContainerService,
                public customAttributeService: services.master.customAttributeService,
                public customAttributeAnswerOptionService: services.master.customAttributeAnswerOptionService,
                private rejectionService: interfaces.master.IRejectionService,
                private containerISOService: interfaces.applicationcore.IContainerISOService,
                private manifestTypeService: interfaces.manifest.IManifestTypeService) {

                this.myHook = $transitions.onSuccess({
                    to: 'auth.Manifests.Update',
                    from: 'auth.Manifests.Update.**'
                }, () => {
                    bsLoadingOverlayService.wrap({
                        referenceId: 'update'
                    }, () => {
                        return this.loadManifest();
                    });
                });

                $scope.$on('$destroy', () => {
                    $transitions.onStart({}, this.myHook)
                });

                var loadPromises: ng.IPromise<any>[] = [];

                loadPromises.push(this.loadCargoTypes());
                loadPromises.push(this.loadManifest());
                //loadPromises.push(this.loadALCSignOff());

                bsLoadingOverlayService.wrap({
                    referenceId: 'update'
                }, () => {
                    return $q.all(loadPromises);
                });
            }

            calculateTabIndex(index:number){
                return index + 20;
            }

            loadManifest() {
                if (this.manifest) {
                    this.manifest.EDIFACTMessageList = undefined;
                    this.manifest.DocumentRepository.DocumentRepositoryFiles = undefined;
                    this.manifest.CostInvoiceList = undefined;
                }

                return this.manifestService.get(this.$stateParams.manId).get().$promise.then(
                    (result) => {

                        this.manifest = result;
                        this.breadCrumbDesc = result.ManifestNumber;

                        this.gvwCrews.data = result.ManifestCrewList;
                        this.gvwConsignments.data = result.ConsignmentList;
                        this.gvwSundryItems.data = result.SundryItemList;
                        this.gvwContainers.data = result.ContainersList;
                        this.gvwManifestAttribute.data = result.ManifestAttributeList;
                        this.getCanEDIRight();

                        this.$timeout(() => {
                            if (this.apiCrews) {
                                this.apiCrews.core.notifyDataChange(this.uiGridConstants.dataChange.ALL)
                            }
                            if (this.apiConsignments) {
                                this.apiConsignments.core.notifyDataChange(this.uiGridConstants.dataChange.ALL)
                            }
                            if (this.apiSundryItem) {
                                this.apiSundryItem.core.notifyDataChange(this.uiGridConstants.dataChange.ALL)
                            }
                            if (this.apiContainersList) {
                                this.apiContainersList.core.notifyDataChange(this.uiGridConstants.dataChange.ALL)
                            }
                            if (this.apiCA) {
                                this.apiCA.core.notifyDataChange(this.uiGridConstants.dataChange.ALL)
                            }
                        });
                    },
                    (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }
                );
            }

            getCanEDIRight() {
                this.canEDI = false;
                return this.menuService.getGTSConnectMenuItem(697).get((result: interfaces.applicationcore.IMenu) => {
                    if (result && result.name) {
                        this.canEDI = true;
                    }
                                        
                }).$promise;
            }

            //Loading Route Codes
            loadRouteCodes() {
                if (this.manifest) {
                    return this.routeCodeService.getDropdownListAll(this.manifest.OwnerEntityId, this.manifest.IsInbound).query({},
                        (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            getTotalALCvalues(key: string) {
                var tmpValue = 0;

                if (this.manifest.ALCSignOffList) {
                    for (var i = this.manifest.ALCSignOffList.length - 1; i >= 0; i--) {
                        tmpValue += parseFloat(this.manifest.ALCSignOffList[i][key]);
                    }
                }

                return tmpValue;
            }

            routeCodeChanged(model: interfaces.applicationcore.IDropdownModel) {
                if (this.manifest) {
                    var loadPromises: ng.IPromise<any>[] = [];

                    loadPromises.push(this.loadRouteDefaults(model.Id));
                    loadPromises.push(this.loadRouteLeadTimes(model.Id, this.manifest.ShippingPriority ? this.manifest.ShippingPriority.Id : 0));

                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'update'
                    }, () => {
                        return this.$q.all(loadPromises).then(() => {

                        })
                    });
                }
            }

            shippingPriorityChanged(model: interfaces.applicationcore.IDropdownModel) {
                if (this.manifest) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'update'
                    }, () => {
                        return this.loadRouteLeadTimes(this.manifest.Route.Id, model ? model.Id : 0);
                    });
                }
            }

            loadRouteDefaults(routeId: number) {
                return this.routeCodeService.getRouteData().query({
                    routeId: routeId,
                },
                    (result: interfaces.master.IRouteCode) => {
                        //Load the data.
                        if (result.LoadingCountry) {
                            this.manifest.LoadingCountry = result.LoadingCountry;
                        }

                        this.manifest.PlaceOfLoading = result.LoadingLocation;

                        if (result.DispatchWarehouse && result.DispatchWarehouse.Id > 0) {
                            this.manifest.DispatchWarehouse = result.DispatchWarehouse;
                        }

                        if (result.DestinationCountry) {
                            this.manifest.DestinationCountry = result.DestinationCountry;
                        }

                        this.manifest.PlaceOfUnloading = result.UnloadingLocation;

                        if (result.ReceiptWarehouse && result.ReceiptWarehouse.Id > 0) {
                            this.manifest.ReceiptWarehouse = result.ReceiptWarehouse;
                        }

                        if (result.TransportMode) {
                            this.manifest.TransportMode = result.TransportMode;
                        }

                        if (result.MasterCartageContractor) {
                            this.manifest.MasterCartageContractor = result.MasterCartageContractor;
                        }

                        if (result.CartageContractor) {
                            this.manifest.CartageContractor = result.CartageContractor;
                        }

                        if (result.TransportType) {
                            this.manifest.TransportType = result.TransportType;
                        }


                        if (result.CustomsDetailTransitList)
                        {
                            this.manifest.ManifestCustomsDetailTransitList = result.CustomsDetailTransitList;
                        }
                        else
                        {
                            this.manifest.ManifestCustomsDetailTransitList = null;
                        }                       




                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }

            //Load Route Lead Times
            loadRouteLeadTimes(routeId: number, shippingPriorityId: number) {
                return this.routeLeadTimesService.getByRoute().query(
                    {
                        routeId: routeId,
                        shippingPriorityId: shippingPriorityId
                    }, (routeLeadTimesData: interfaces.master.IRouteLeadTimes) => {
                        //Get the data back.
                        this.manifest.RouteLeadTimes = routeLeadTimesData;
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }

            //Loading Transport Modes
            loadTransportModes() {
                return this.transportModeService.getDropdownList().query({},
                    (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }

            //Loading Loading Country
            loadCountries() {
                return this.countryService.getDropdownList().query({
                },
                    (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }

            //Loading Loading Location
            loadLoadingLocation(searchText: string) {
                if (this.manifest && this.manifest.LoadingCountry) {
                    return this.unLocationCodeService.getDropdownList().query({
                        countryId: this.manifest.LoadingCountry.Id,
                        searchText: searchText
                    },
                        (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            //Loading Dispatch Warehouse
            loadDispatchWarehouse() {
                if (this.manifest && this.manifest.LoadingCountry) {
                    return this.warehouseService.getDropdownList().query({
                        ownerEntityId: this.manifest.OwnerEntityId,
                        CountryId: this.manifest.LoadingCountry.Id
                    },
                        (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            //Loading Unloading Location
            loadUnloadingLocation(searchText: string) {
                if (this.manifest && this.manifest.DestinationCountry) {
                    return this.unLocationCodeService.getDropdownList().query({
                        countryId: this.manifest.DestinationCountry.Id,
                        searchText: searchText
                    },
                        (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            //Loading Receipt Warehouse
            loadReceiptWarehouse() {
                if (this.manifest && this.manifest.DestinationCountry) {
                    return this.warehouseService.getDropdownList().query({
                        ownerEntityId: this.manifest.OwnerEntityId,
                        CountryId: this.manifest.DestinationCountry.Id
                    },
                        (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            //Loading Master Contractor
            loadCartageContractors() {
                if (this.manifest) {
                    return this.cartageContractorService.getDropdownList().query({
                        ownerEntityId: this.manifest.OwnerEntityId
                    },
                        (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }

            }

            //Load Notify Parties
            public loadNotifyParties() {
                var defer = this.$q.defer();

                if (this.manifest) {
                    this.notifyPartyService.getDropdownList().query({ ownerEntityId: this.manifest.OwnerEntityId }, (data) => {
                        defer.resolve(data);
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        defer.resolve([]);
                    });
                } else {
                    defer.resolve([]);
                }

                return defer.promise;
            }

            //Loading Cargo Types
            loadCargoTypes() {
                return this.cargoTypeService.GetList().query(
                    (result: Array<interfaces.applicationcore.IDropdownModel>) => {
                        this.cargoTypeList = result;
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }

            //Loading Transport Type
            loadTransportType(searchText: string) {
                if (this.manifest) {
                    return this.transportTypeService.getDropdownList(this.manifest.OwnerEntityId, searchText).query({
                    },
                        (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            //Load Shipping Priorities
            public loadShippingPriorities() {
                var defer = this.$q.defer();

                if (this.manifest) {
                    this.shippingPriorityService.getDropdownList().query({ ownerEntityId: this.manifest.OwnerEntityId, type: Enum.EnumShippingPriorityType.Manifest }, (data) => {
                        defer.resolve(data);
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        defer.resolve([]);
                    });
                } else {
                    defer.resolve([]);
                }

                return defer.promise;
            }

            //estimateDateChanged
            public estimateDateChanged(newValue: moment.Moment, forward: boolean) {
                this.calculateLeadTimes(forward, newValue);
            }

            public calculateLeadTimes(forward: boolean, newValue: moment.Moment) {
                if (!newValue)
                    return;

                if (!this.manifest.RouteLeadTimes)
                    return;

                if (!forward) {
                    this.manifest.EstimateDateOfArrival = newValue.clone().add((this.manifest.RouteLeadTimes.RequiredDateOfArrival * -1), "day");
                    this.manifest.ShipByDate = this.manifest.EstimateDateOfArrival.clone().add((this.manifest.RouteLeadTimes.ETA * -1), "day");
                    this.manifest.EstimatedDateLoading = this.manifest.ShipByDate.clone().add((this.manifest.RouteLeadTimes.ShipByDate * -1), "day");
                    this.manifest.EstimatedDestinationClearanceDate = this.manifest.EstimateDateOfArrival.clone().add((this.manifest.RouteLeadTimes.DestinationClearing * 1), "day");
                    this.manifest.EstimatedDateOfClearance = this.manifest.ShipByDate.clone().add((this.manifest.RouteLeadTimes.CustomsClearedDate * -1), "day");
                } else {
                    this.manifest.EstimatedDateOfClearance = newValue.clone().add((this.manifest.RouteLeadTimes.CustomsClearedDate * -1), "day");
                    this.manifest.ShipByDate = newValue.clone().add((this.manifest.RouteLeadTimes.ShipByDate * 1), "day");
                    this.manifest.EstimateDateOfArrival = this.manifest.ShipByDate.clone().add((this.manifest.RouteLeadTimes.ETA * 1), "day");
                    this.manifest.EstimatedDestinationClearanceDate = this.manifest.EstimateDateOfArrival.clone().add((this.manifest.RouteLeadTimes.DestinationClearing * 1), "day");
                    
                    if (this.manifest.IsInbound)
                        this.manifest.EstimateArrivalAtDestuff = this.manifest.EstimateDateOfArrival.clone().add((this.manifest.RouteLeadTimes.RequiredDateOfArrival * 1), "day");
                    else
                        this.manifest.EstimateArrivalAtDestuff = this.manifest.EstimatedDestinationClearanceDate.clone().add((this.manifest.RouteLeadTimes.RequiredDateOfArrival * 1), "day");
                }
            }

            vesselSelected(vessel: interfaces.logistics.IVesselLookup) {
                this.manifest.TransportName = vessel.VesselName;
                this.manifest.TransportNumber = vessel.VoyageNumber;
                this.manifest.VesselRadioCallSign = vessel.VesselCallSign;
                this.manifest.VesselOperator = vessel.CustomsOperatorCode;

                if (this.manifest.IsInbound){
                   // this.manifest.EstimateDateOfArrival = vessel.DateOfArrival;
                }
                else
                {
                   // this.manifest.EstimateShipByDate = vessel.DateOfDeparture;
                    this.manifest.StackDatesFrom = vessel.StackOpening;
                    this.manifest.StackDatesTo = vessel.StackClosing;
                }

            }

            //Loading Route Codes
            loadUsers(searchText: string) {
                return this.userAccountService.getDropdownList(searchText, this.manifest.OwnerEntityId).query(
                    (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }

            showManifestHistory_click() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'update'
                }, () => {
                    return this.manifestService.getStatusHistory(this.manifest.Id).query((result) => {
                        this.showManifestHistory(result);
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                });
            }

            showManifestHistory(statusList: interfaces.manifest.IManifestStatusHistory[]) {
                var modalInstance = this.$uibModal.open({
                    animation: true,
                    templateUrl: 'templates/modules/applicationmain/master/statusControlViewHistory.html',
                    controller: ['$uibModalInstance', 'TransactionStatusHistoryList', '$scope', ($modalInstance, TransactionStatusHistoryList, $scope) => {
                        $scope.TransactionStatusHistoryList = TransactionStatusHistoryList;
                    }],
                    size: "lg",
                    resolve: {
                        TransactionStatusHistoryList: () => { return statusList }
                    }
                });

                modalInstance.result.then(() => { }, () => { })
            }

            loadManifestCustomsType() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.ManifestType
                }, () => {

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadingCountryChanged(model: interfaces.applicationcore.IDropdownModel) {
                this.loadingOfficeList = [];

            }

            unloadingCountryChanged(model: interfaces.applicationcore.IDropdownModel) {
                this.unloadingOfficeList = [];

            }

        

            loadTransitCountryOffices(searchText: string, transitCountryId: number) {
                if (this.manifest && transitCountryId) {
                    return this.customsOfficeCodeService.getForDropdown(searchText, transitCountryId).query().$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

         


            apiSundryItem: uiGrid.IGridApi;
            gvwSundryItems: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                showGridFooter: true,
                cellEditableCondition: () => { return this.manifest.LockdownType < 5 },
                onRegisterApi: (gridApi) => {
                    this.apiSundryItem = gridApi;

                    this.apiSundryItem.cellNav.on.navigate(this.$scope, (newCol) => {
                        this.$timeout(() => {
                            this.apiSundryItem.selection.selectRow(newCol.row.entity);
                        });
                    });
                },
                columnDefs: [{
                    name: "EDIT",
                    field: "Id",
                    displayName: "",
                    enableFiltering: false,
                    width: 32,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    cellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" class="btn btn-default btn-sm" ui-sref="auth.Manifests.Update.SundryItem({ msiId: MODEL_COL_FIELD,isInbound : grid.appScope.manifestUpdateCtrl.manifest.IsInbound  })" ng-if="MODEL_COL_FIELD && grid.appScope.manifestUpdateCtrl.manifest.LockdownType < 5">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    headerCellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.manifestUpdateCtrl.addInlineSundryItem()" ng-if="grid.appScope.manifestUpdateCtrl.manifest.LockdownType < 5">
                            <span class="fa fa-plus"></span>
                        </button>
                    </div>`
                }, {
                    name: "SEQNUM",
                    displayName: "#",
                    field: "SequenceNumber",
                    enableFiltering: false,
                    type: "number",
                    cellClass: 'text-right',
                     width: 30,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                }, {
                    name: "SUNITM",
                    displayName: "*Sundry Item",
                    field: "SundryItem",
                    enableFiltering: true,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    width: 200,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `<form name="inputForm">
                                                <gts-dropdown ui-select-wrap name="code-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.manifestUpdateCtrl.loadSundryItems(searchText)" ng-change="grid.appScope.manifestUpdateCtrl.sundryItemChanged(model, row.entity)" required="true" pagination-search="true" refresh-delay="1000"></gts-dropdown>
                                            </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                        }
                    },
                    sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                        var nulls = this.apiSundryItem.core.sortHandleNulls(a, b);
                        if (nulls !== null) {
                            return nulls;
                        } else {
                            if (a === b) {
                                return 0;
                            }

                            if (direction === "desc") {
                                if (a.Display < b.Display) return -1;
                                if (a.Display > b.Display) return 1;
                            } else if (direction === "asc") {
                                if (a.Display > b.Display) return 1;
                                if (a.Display < b.Display) return -1;
                            }

                            return 0;
                        }
                    }
                }, {
                    name: "SUNDESC",
                    displayName: "*Sundry Item Description",
                    field: "SundryItemDescription",
                    width: 300,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-sunDesc" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                }, {
                    name: "COSTEL",
                    displayName: "Cost Model Element",
                    field: "CostModelElement",
                    width: 175,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                },{
                    name: "QTY",
                    displayName: "*Quantity",
                    field: "Quantity",
                    cellClass: 'text-right',
                    width: 75,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellFilter: "number: 2",
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" ignore-wheel name="{{MODEL_COL_FIELD.$$hashKey}}-qty" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.01" ng-change="grid.appScope.manifestUpdateCtrl.quantityChanged(row.entity)" />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                }, {
                    name: "SELLPRICE",
                    displayName: "*Selling Price",
                    field: "SellingPrice",
                    cellClass: 'text-right',
                    width: 150,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellFilter: "number: 2",
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" ignore-wheel name="{{MODEL_COL_FIELD.$$hashKey}}-sellprice" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.01" ng-change="grid.appScope.manifestUpdateCtrl.sellingPriceChanged(row.entity)" />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" ignore-wheel class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }" step="0.01"/></div>
                            </div>`
                }, {
                    name: "SELLVALUE",
                    displayName: "*Selling Value",
                    field: "SellingValue",
                    cellClass: 'text-right',
                    width: 150,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    cellFilter: "number: 2",
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                <input type="number" ignore-wheel class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }" step="0.01"/></div>
                            </div>`
                }, {
                    name: "VATVALUE",
                    displayName: "*VAT Value",
                    field: "VATValue",
                    cellClass: 'text-right',
                    width: 150,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    cellFilter: "number: 2",
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                <input type="number" ignore-wheel class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }" step="0.01"/></div>
                            </div>`
                }, {
                    name: "CURCODE",
                    displayName: "*Currency",
                    field: 'Currency',
                    enableCellEdit: true,
                    width: 200,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `<form name="inputForm">
                                                <gts-dropdown ui-select-wrap name="currency-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.manifestUpdateCtrl.loadCurrencies(searchText)" required="true"></gts-dropdown>
                                            </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            if (cellValue) {
                                return (cellValue.Code.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                            } else {
                                return false;
                            }
                        }
                    },
                    sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                        var nulls = this.apiSundryItem.core.sortHandleNulls(a, b);
                        if (nulls !== null) {
                            return nulls;
                        } else {
                            if (a === b) {
                                return 0;
                            }

                            if (direction === "desc") {
                                if (a.Display < b.Display) return -1;
                                if (a.Display > b.Display) return 1;
                            } else if (direction === "asc") {
                                if (a.Display > b.Display) return 1;
                                if (a.Display < b.Display) return -1;
                            }

                            return 0;
                        }
                    }
                    }, {
                        name: "ISDUT",
                        displayName: "Dutiable?",
                        field: "IsDutiable",
                        width: 100,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: '<input type="checkbox" ng-model="MODEL_COL_FIELD" disabled>',
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                    }, {
                        name: "INCLPRICE",
                        displayName: "Include In Price?",
                        field: "IsIncludedInPrice",
                        width: 150,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: '<input type="checkbox" ng-model="MODEL_COL_FIELD" disabled>',
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                    }, {
                        name: "INCLINV",
                        displayName: "Include On Invoice?",
                        field: "IsIncludedOnInvoice",
                        width: 150,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: '<input type="checkbox" ng-model="MODEL_COL_FIELD" disabled>',
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                    }
                    ]
            };

            loadSundryItems(searchText: string) {
                return this.sundryItemService.getDropdownList(this.manifest.OwnerEntityId,this.manifest.IsInbound, searchText).query(() => {

                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            sundryItemChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.manifest.IManifestSundryItemDisplay) {
                if (model) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'update'
                    },
                        () => {
                            return this.sundryItemService.getDefault().get({ sunId: model.Id, countryId: this.manifest.LoadingCountry.Id }, (result: interfaces.master.ISundryItemDefault) => {
                                entity.IsDutiable = result.Dutiable;
                                entity.IsIncludingInPrice = result.IsIncludedInPrice;
                                entity.VATRate = result.VatRateRate;
                                entity.IsIncludedOnInvoice = !result.IsManifestExludeFromConsignment; //Opposite.

                                if (result.CostModelElement) {
                                    entity.CostModelElement = result.CostModelElement.Description;
                                }

                                entity.SundryItemDescription = result.Description;

                                entity.Quantity = 1;
                                entity.SellingPrice = 0;
                                entity.VATValue = 0;
                                entity.SellingValue = 0;

                                if (result.Currency) {
                                    entity.Currency = result.Currency;
                                }

                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
                }
            }

            loadCurrencies() {
                return this.currencyService.getCurrencyDropdownList().query((currencyList: Array<interfaces.applicationcore.IDropdownModel>) => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            quantityChanged(entity: interfaces.logistics.ICommercialInvoiceSundryItemDisplay) {
                this.calculateVat(entity);
            }

            sellingPriceChanged(entity: interfaces.logistics.ICommercialInvoiceSundryItemDisplay) {
                this.calculateVat(entity);
            }

            calculateVat(entity: interfaces.logistics.ICommercialInvoiceSundryItemDisplay) {
                entity.SellingValue = Number((Math.round(entity.Quantity * entity.SellingPrice).toFixed(2)));
                entity.VATValue = Number((Math.round(entity.SellingValue * entity.VATRate) / 100));
            }

            addInlineSundryItem() {
                this.manifest.SundryItemList.push(<interfaces.manifest.IManifestSundryItemDisplay>{
                    Quantity: 1,
                    SellingValue: 0,
                    SellingPrice: 0,
                    VATValue: 0
                });

                //(<Array<any>>(this.gvwSundryItems.data)).splice(0, 0, { newSundryItem });
            }

            removeSundryItem() {
                var selectedRow = <interfaces.manifest.IManifestSundryItemDisplay>this.apiSundryItem.selection.getSelectedRows()[0];
                if (selectedRow && selectedRow.Id > 0) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'update'
                    }, () => {
                        return this.manifestSundryItemService.delete(selectedRow.Id).delete((result) => {
                            this.generalService.displayMessageHandler(result);
                            if (!result.HasErrorMessage) {
                                this.$timeout(() => {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'update'
                                    }, () => {
                                        return this.loadManifest();
                                    });
                                });
                            }

                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
                } else if (selectedRow) {
                    _.remove(this.gvwSundryItems.data, (o) => {
                        return o === selectedRow;
                    });
                }
            }

            downloadASYCUDAXML_click(countryId:number) {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'update'
                }, () => {
                    return this.SaveManifest().then((result) => {

                        if (result.HasErrorMessage) {
                            this.generalService.displayMessageHandler(result);
                        }

                        this.$timeout(() => {
                            this.downloadASYCUDAXML(countryId)
                        });
                    });
                });
            }

            downloadASYCUDAXML(countryId:number) {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'update'
                }, () => {
                    return this.manifestService.validateAndDownloadASYCUDAXml(this.manifest.Id,countryId).save((result) => {
                        if (result.HasErrorMessage) {
                            this.generalService.displayMessageHandler(result);
                        }

                        this.showDownloadModal(result.value);

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                });
            }

            showDownloadModal(xmlValue: string) {
                var modalInstance = this.$uibModal.open({
                    animation: true,
                    backdrop: 'static',
                    template: `
<div class="modal-header">
    <strong class="modal-title">{{manifestNumber}}.xml</strong>
</div>
<div class="modal-body">
    <div class="gts-padding row">
        <div class="row vertical-align">
            <div class="col-md-12 col-sm-12">
                <textarea id="copy-area" class="form-control" ng-model="xmlString" rows="25" readonly></textarea>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
                <div class="pull-right">
                    <button type="button" class="btn btn-primary" ng-click="Copy($event)">Copy</button>
                    <button type="button" class="btn btn-primary" ng-click="Save()">Save</button>
                    <button type="button" class="btn btn-info" ng-click="Close()">Close</button>
                </div>
</div>`,
                    controller: ['$uibModalInstance', '$scope', "xmlString", "manifestNumber", "$http", ($modalInstance, $scope, xmlString: string, manifestNumber: string, $http: ng.IHttpService) => {
                        $scope.manifestNumber = manifestNumber;
                        $scope.xmlString = xmlString;

                        $scope.Save = () => {
                            var filename = manifestNumber + '.xml';
                            var fileBlob = new Blob([xmlString], { type: 'text/xml' });

                            if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                                window.navigator.msSaveBlob(fileBlob, filename);
                            } else { // for other browsers
                                var pdfDownload = document.createElement("a");
                                document.body.appendChild(pdfDownload);
                                var fileURL = window.URL.createObjectURL(fileBlob);
                                pdfDownload.href = fileURL;
                                pdfDownload.download = filename;
                                pdfDownload.click();
                                pdfDownload.remove();
                            }
                        }

                        $scope.Copy = ($event: EventTarget) => {
                            try {
                                var textarea = document.getElementById('copy-area');
                                textarea.focus();

                                var successful = document.execCommand('selectAll');
                                if (!successful) throw successful;

                                successful = document.execCommand('copy');
                                if (!successful) throw successful;

                            } catch (err) {
                                window.prompt("Copy to clipboard: Ctrl+C, Enter", $scope.xmlString);
                            }
                        };

                        $scope.Close = () => {
                            $modalInstance.close();
                        };
                    }],
                    resolve: {
                        xmlString: () => { return xmlValue },
                        manifestNumber: () => { return this.manifest.ManifestNumber; }
                    }
                });

                modalInstance.result.then(() => { }, () => { })
            }

            refresh() {
                this.$timeout(() => {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'update'
                    }, () => {
                        return this.loadManifest();
                    });
                });
            }

            validateEDI() {
                this.$timeout(() => {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'update'
                    }, () => {
                        return this.SaveAndValidateManifest().then((result) => {

                            this.generalService.displayMessageHandler(result);

                            if (!result.HasErrorMessage) {
                                this.$timeout(() => {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'update'
                                    }, () => {
                                        return this.loadManifest();
                                    });
                                });
                            }
                        });
                    });
                });
            }

            sendEDI() {
                this.$timeout(() => {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'update'
                    }, () => {
                        return this.SaveAndSendManifest().then((result) => {

                            this.generalService.displayMessageHandler(result);

                            if (!result.HasErrorMessage) {
                                this.$timeout(() => {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'update'
                                    }, () => {
                                        return this.loadManifest();
                                    });
                                });
                            }
                        });
                    });
                });

            }

            apiCrews: uiGrid.IGridApi;
            gvwCrews: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                showGridFooter: true,
                cellEditableCondition: () => { return !this.manifest.IsSARSEDI && this.manifest.LockdownType < 5},
                onRegisterApi: (gridApi) => {
                    this.apiCrews = gridApi;

                    this.apiCrews.cellNav.on.navigate(this.$scope, (gridSelection) => {
                        this.apiCrews.selection.selectRow(gridSelection.row.entity);
                    });
                },
                columnDefs: [{
                    name: "EDIT",
                    field: "Id",
                    displayName: "",
                    enableFiltering: false,
                    width: 32,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    cellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" class="btn btn-default btn-sm" ui-sref="auth.Manifests.Update.Crew({ macId: MODEL_COL_FIELD })" ng-if="MODEL_COL_FIELD">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    headerCellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" class="btn btn-default btn-sm"  ng-click="grid.appScope.manifestUpdateCtrl.addInlineCrew()" ng-if="grid.appScope.manifestUpdateCtrl.manifest.LockdownType < 5 && !grid.appScope.manifest.IsSARSEDI">
                            <span class="fa fa-plus"></span>
                        </button>
                    </div>`
                }, {
                    name: "ISDRIVER",
                    displayName: "Is Driver",
                    field: "IsDriver",
                    width: 100,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellTemplate: '<input type="checkbox" ng-model="MODEL_COL_FIELD" ng-change="grid.appScope.manifestUpdateCtrl.checkBoxChanged(row.entity)" ng-disabled="grid.appScope.manifestUpdateCtrl.manifest.IsSARSEDI || grid.appScope.manifestUpdateCtrl.manifest.LockdownType > 4">',
                    editableCellTemplate: `
                                    <form name="inputForm">
                                        <p class="input-group-sm">
                                            <input type="checkbox" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" ui-grid-editor>
                                        </p>
                                    </form>`,
                    filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                        </div>`
                }, {
                    name: "FullName",
                    displayName: "Full Name",
                    field: "Crew",
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    width: 200,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `<form name="inputForm">
                                                <gts-dropdown ui-select-wrap name="driver-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.manifestUpdateCtrl.loadCrewMembers(searchText)" required="true" pagination-search="true" refresh-delay="1000"
                                                    ng-change="grid.appScope.manifestUpdateCtrl.driverChanged(model, row.entity)"></gts-dropdown>
                                            </form>`,
                    filterHeaderTemplate: `
                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                        <div class="input-group-sm">
                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                    </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (searchTerm.length === 0 || cellValue.Code.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || cellValue.Description.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0);
                        }
                    },
                    sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                        var nulls = this.apiCrews.core.sortHandleNulls(a, b);
                        if (nulls !== null) {
                            return nulls;
                        } else {
                            if (a === b) {
                                return 0;
                            }

                            if (direction === "desc") {
                                if (a.Code < b.Code) return -1;
                                if (a.Code > b.Code) return 1;
                            } else if (direction === "asc") {
                                if (a.Code > b.Code) return 1;
                                if (a.Code < b.Code) return -1;
                            }

                            return 0;
                        }
                    }
                }, {
                    name: "DOCNUM",
                    displayName: "Document Number",
                    field: "DocumentNumber",
                    width: 250,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    editableCellTemplate: `
                                    <form name="inputForm">
                                        <p class="input-group-sm">
                                            <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-code" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                        </p>
                                    </form>`,
                    filterHeaderTemplate: `
                                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                                        <div class="input-group-sm">
                                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                                    </div>`
                }, {
                    name: "ISSBY",
                    displayName: "Issued By",
                    field: "DocumentIssuedCountryCode",
                    width: 150,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    editableCellTemplate: `
                                    <form name="inputForm">
                                        <p class="input-group-sm">
                                            <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-code" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                        </p>
                                    </form>`,
                    filterHeaderTemplate: `
                                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                                        <div class="input-group-sm">
                                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                                    </div>`
                }, {
                    name: "EXPDATE",
                    displayName: "Expiry Date",
                    field: "DocumentExpiry",
                    width: 250,
                    enableCellEdit: false,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="expDate"></gts-date>
                                        
                            </div>`,
                    filter: {
                        condition: function (searchTerm, cellValue) {
                            if (cellValue) {
                                return cellValue.isSameOrAfter(searchTerm);
                            } else {
                                return false;
                            }
                        }
                    }
                }, {
                    name: "IDNUM",
                    displayName: "ID Number",
                    field: "IDNumber",
                    width: 250,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    editableCellTemplate: `
                                    <form name="inputForm">
                                        <p class="input-group-sm">
                                            <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-code" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                        </p>
                                    </form>`,
                    filterHeaderTemplate: `
                                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                                        <div class="input-group-sm">
                                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                                    </div>`
                }]
            };
            
            addInlineCrew() {
                var crewMember = {};
                (<Array<any>>(this.gvwCrews.data)).splice(0, 0, crewMember);

                this.$timeout(() => {
                    this.apiCrews.rowEdit.setRowsDirty([crewMember]);
                });
            }

            checkBoxChanged(entity) {
                if (this.apiCrews) {
                    this.apiCrews.rowEdit.setRowsDirty([entity]);
                }
            }

            loadCrewMembers(searchText) {
                return this.crewService.getForDropdown(this.manifest.OwnerEntityId, searchText).query(() => {

                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            removeManifestCrew() {
                var selectedRow = <interfaces.manifest.IManifestCrewDisplay>this.apiCrews.selection.getSelectedRows()[0];
                if (selectedRow && selectedRow.Id > 0) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'update'
                    }, () => {
                        return this.manifestCrewService.remove(selectedRow.Id).delete((result) => {
                            this.generalService.displayMessageHandler(result);
                            if (!result.HasErrorMessage) {
                                this.$timeout(() => {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'update'
                                    }, () => {
                                        return this.loadManifest();
                                    });
                                });
                            }

                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
                } else if (selectedRow) {
                    this.apiCrews.rowEdit.setRowsClean([selectedRow]);
                    _.remove(this.gvwCrews.data, (o) => {
                        return o === selectedRow;
                    });
                }
            }

            driverChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.manifest.IManifestCrewDisplay) {
                if (model) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'update'
                    }, () => {
                        return this.loadCrewDefaults(model.Id).then((result) => {
                            entity.Crew = <interfaces.applicationcore.IDropdownModel>{
                                Id: result.Id,
                                Code: result.Name,
                                Description: result.Surname,
                                Display: (result.Name + " " + result.Surname),
                                Selected: true
                            };

                            entity.DocumentExpiry = result.DocumentExpiry;
                            entity.IDNumber = result.IDNumber;
                            entity.DocumentIssuedCountryCode = result.DocumentIssuedCountryCode;
                            entity.DocumentNumber = result.DocumentNumber;
                        });
                    });
                }
            }

            loadCrewDefaults(crwId: number) {
                return this.crewService.get(crwId).get(() => {

                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }


            generateCostInvoices() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'update'
                }, () => {
                    return this.commercialInvoiceService.generateCostInvoices(undefined, this.manifest.Id).save((result) => {
                        this.generalService.displayMessageHandler(result);

                        if (!result.HasErrorMessage) {
                            this.$timeout(() => {
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'update'
                                }, () => {
                                    return this.loadManifest();
                                });
                            });
                        }
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                });
            }

            editCostInvoice(costInvoiceId: number) {
                this.$state.go("auth.Manifests.Update.CostInvoice", { comId: costInvoiceId,manIsInbound: this.manifest.IsInbound });
            }

            viewCostInvoiceDocuments(costInvoiceId: number) {
                this.$state.go("auth.Manifests.Update.CostInvoice.Documents", { comId: costInvoiceId});
            }

            removeCostInvoice(costInvoiceId: number) {
                if (costInvoiceId) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'update'
                    }, () => {
                        return this.commercialInvoiceService.delete(costInvoiceId).delete((result) => {
                            this.generalService.displayMessageHandler(result);
                            if (!result.HasErrorMessage) {
                                this.$timeout(() => {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'update'
                                    }, () => {
                                        return this.loadManifest();
                                    });
                                });
                            }
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });

                }
            }

            getDocuments(showAll:boolean) {
                return this.documentRepositoryService.getDocumentRepository().query({ referenceNumber: this.manifest.ManifestNumber, systemType: Enum.EnumSystemType.Manifest, ownerEntityId: this.manifest.OwnerEntityId,showall:showAll }, (documentRepository: interfaces.documentManagement.IDocumentRepository) => {
                    this.manifest.DocumentRepository = documentRepository;

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            refreshDocuments(showAll:boolean) {
                this.showAll = showAll;
                this.manifest.DocumentRepository.DocumentRepositoryFiles = null;
                return this.getDocuments(showAll);
            }

            printDocuments() {
                this.$state.go("auth.Manifests.Update.Documents", { version: this.manifest.Version });
            }

            removeInlineCA() {
                var selectedRow = <interfaces.master.ICustomAttributeValue>this.apiCA.selection.getSelectedRows()[0];
                
                if (selectedRow && selectedRow.Id > 0) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'update'
                    }, () => {
                            return this.customAttributeService.deleteCustomAttributeValue(selectedRow.Id).save({ Id: selectedRow.Id }, (result) => {
                            this.generalService.displayMessageHandler(result);
                            this.$timeout(() => {
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'update'
                                }, () => {
                                    this.loadManifest();
                                });
                            });
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        });
                    });
                } else if (selectedRow) {
                    _.remove(this.gvwManifestAttribute.data, (o) => {
                        return o === selectedRow;
                    });
                }
            }

            checkBoxAttributeChanged(entity) {
                if (this.apiCA) {
                    this.apiCA.rowEdit.setRowsDirty([entity]);
                }
            }

            attributeValueChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.master.ICustomAttributeValue) {
                if (model) {
                    entity.AttributeValue = model.Description;

                    if (this.apiCA) {
                        this.apiCA.rowEdit.setRowsDirty([entity]);
                    }
                }
            }

            attributeValueOtherChanged(entity: interfaces.master.ICustomAttributeValue) {
                if (entity) {
                    if (this.apiCA) {
                        this.apiCA.rowEdit.setRowsDirty([entity]);
                    }
                }
            }

            insertCA() {
                this.manifest.ManifestAttributeList.push(<interfaces.master.ICustomAttributeValue>{
                    Id: 0,
                    Attribute: <interfaces.master.ICustomAttribute>{ Id: 0, AttributeType: 0, IsNotEditable: false },
                    CategoryType: 785,
                    CategoryKey: this.manifest.Id,
                    IsActive: true
                });
            }

            addAllAttributes() {
                
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'update'
                }, () => {
                        return this.SaveManifest().then(() => {
                            this.customAttributeService.generateMandatoryAttributes(this.manifest.Id, 785).save((result) => {
                                this.generalService.displayMessageHandler(result);

                                if (!result.HasErrorMessage) {
                                    this.$timeout(() => {
                                        this.bsLoadingOverlayService.wrap({
                                            referenceId: 'update'
                                        }, () => {
                                            return this.loadManifest();
                                        });
                                    });
                                }
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            });
                        }, () => {
                            return;
                        });
                    });
            }

            loadAttributeList() {
                return this.customAttributeService.GetCustomAttributeByCategory(785, this.manifest.OwnerEntityId).query({
                }, (resultList) => {
                    this.attributeList = resultList;
                }).$promise;
            }

            loadAttributeAnswerOptionList(id) {
                return this.customAttributeAnswerOptionService.GetDropDownListByAttribute().query({
                    attributeId: id
                }, (resultList) => {
                    this.attributeAnswerOptionList = resultList;
                }).$promise;
            }

            gvwManifestAttribute: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableRowHeaderSelection: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableHorizontalScrollbar: 2,
                rowTemplate: '<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ng-class="col.colIndex()" class="ui-grid-cell" ng-disabled="row.entity.Attribute.IsNotEditable" ui-grid-cell></div>',
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => {
                    this.apiCA = gridApi;

                    this.apiCA.cellNav.on.navigate(this.$scope, (newCol) => {
                        this.$timeout(() => {
                            this.apiCA.selection.selectRow(newCol.row.entity);
                        });
                    });
                },
                columnDefs: [
                    {
                        name: "EDIT",
                        displayName: "",
                        field: "Id",
                        cellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" ng-click="grid.appScope.manifestUpdateCtrl.removeInlineCA()" ng-disabled="row.entity.Attribute.IsNotEditable" class="btn btn-warning btn-sm">
                                Delete
                            </button>
                        </div>`,
                        enableSorting: false,
                        enableColumnMenu: false,
                        enableCellEdit: false,
                        width: 80,
                        enableCellEditOnFocus: false,
                        headerCellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" class="btn btn-primary btn-sm" ng-click="grid.appScope.manifestUpdateCtrl.addAllAttributes()" >
                                <span class="fa fa-list"></span>
                            </button>
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.manifestUpdateCtrl.insertCA()" >
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`
                    },
                    {
                        name: 'Attribute',
                        displayName: '* Attribute',
                        field: 'Attribute',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents" ng-disabled="row.entity.Attribute.IsNotEditable">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" ng-disabled="row.entity.Attribute.IsNotEditable" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.manifestUpdateCtrl.loadAttributeList()"></gts-dropdown>
                                </form>`,
                        enableSorting: false,
                        width: 300
                    },
                    {
                        name: 'AttributeValue',
                        displayName: '* Attribute Value',
                        field: 'CustomAttributeAnswerOption',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents" ng-if="row.entity.Attribute.AttributeType == 57" ng-disabled="row.entity.Attribute.IsNotEditable">{{row.entity.AttributeValue}}</div>
                                       <div class="ui-grid-cell-contents" ng-if="row.entity.Attribute.AttributeType == 58" ng-disabled="row.entity.Attribute.IsNotEditable">{{row.entity.AttributeValue}}</div>
                                       <div class="ui-grid-cell-contents" ng-if="row.entity.Attribute.AttributeType == 556" ng-disabled="row.entity.Attribute.IsNotEditable">{{row.entity.AttributeNumericValue}}</div>
                                       <div class="ui-grid-cell-contents" ng-if="row.entity.Attribute.AttributeType == 557" ng-disabled="row.entity.Attribute.IsNotEditable">{{row.entity.AttributeDateValue | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                        <gts-dropdown ng-if="row.entity.Attribute.AttributeType == 57" ng-disabled="row.entity.Attribute.IsNotEditable" ng-change="grid.appScope.manifestUpdateCtrl.attributeValueChanged(model, row.entity)"  ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}-AttributeValueDD" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.manifestUpdateCtrl.loadAttributeAnswerOptionList(row.entity.Attribute.Id)"></gts-dropdown>                                                                                                                                               
                                        
                                        <p class="input-group-sm" ng-if="row.entity.Attribute.AttributeType == 58">
                                        <input type="text" ng-change="grid.appScope.manifestUpdateCtrl.attributeValueOtherChanged( row.entity)" ng-if="row.entity.Attribute.AttributeType == 58" ng-disabled="row.entity.Attribute.IsNotEditable"  ignore-wheel name="AttributeNumericValueText" ng-model="row.entity.AttributeValue" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                        </p>

                                        <p class="input-group-sm"  ng-if="row.entity.Attribute.AttributeType == 556">
                                        <input type="number" ng-change="grid.appScope.manifestUpdateCtrl.attributeValueOtherChanged(row.entity)" ng-if="row.entity.Attribute.AttributeType == 556" ng-disabled="row.entity.Attribute.IsNotEditable"  ignore-wheel name="AttributeNumericValue" ng-model="row.entity.AttributeNumericValue" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.01" min="0" />
                                        </p>

                                        <gts-date ng-change="grid.appScope.manifestUpdateCtrl.attributeValueOtherChanged(row.entity)" ng-if="row.entity.Attribute.AttributeType == 557" ng-disabled="row.entity.Attribute.IsNotEditable"  format="yyyy/MM/dd" ng-model="row.entity.AttributeDateValue" name="{{MODEL_COL_FIELD.$$hashKey}}-endDate"></gts-date>
                                </form>
                               `,
                        enableSorting: false,
                        width: 300
                    }
                    , {
                        name: "IsActive",
                        displayName: "Active",
                        enableFiltering: false,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellTemplate: `<input type="checkbox" ng-disabled="row.entity.Attribute.IsNotEditable" ng-model="row.entity.IsActive" ng-change="grid.appScope.manifestUpdateCtrl.checkBoxAttributeChanged(row.entity)">`,
                        editableCellTemplate: ` <form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox" ng-disabled="row.entity.Attribute.IsNotEditable" ng-model="row.entity.IsActive">
                                                    </p>
                                                </form>`,
                        width: 60
                    }
                ]
            };

            apiConsignments: uiGrid.IGridApi;
            gvwConsignments: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                showGridFooter: true,
                onRegisterApi: (gridApi) => {
                    this.apiConsignments = gridApi;

                    this.apiConsignments.cellNav.on.navigate(this.$scope, (gridSelection) => {
                        this.apiConsignments.selection.selectRow(gridSelection.row.entity);
                    });
                },
                columnDefs: [{
                    name: "EDIT",
                    field: "Id",
                    displayName: "",
                    enableFiltering: false,
                    width: 32,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    cellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.manifestUpdateCtrl.editConsignment(MODEL_COL_FIELD, row.entity.IsInbound)">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`
                }, {
                    name: "EDIStatus",
                    displayName: "EDI",
                    field: "EDIStatus",
                    enableFiltering: false,
                    cellTemplate: `
                        <div class="GridButton">
                            <center>
                                <div ng-if="row.entity.EDIStatusIndicator === 0">
                                    <button type="button" ng-click="grid.appScope.manifestUpdateCtrl.openEDIPopup(row.entity.Id)" class="btn btn-default btn-sm">
                                        <span class="fa fa-edit"></span>
                                    </button>
                                </div>
                                <div ng-if="row.entity.EDIStatusIndicator === 4">
                                    <button type="button" ng-click="grid.appScope.manifestUpdateCtrl.openEDIPopup(row.entity.Id)" class="btn btn-default btn-sm">
                                        <span class="fa fa-times" style="color: #cb2330;"></span>
                                    </button>
                                </div>
                                <div ng-if="row.entity.EDIStatusIndicator === 3">
                                    <button type="button" ng-click="grid.appScope.manifestUpdateCtrl.openEDIPopup(row.entity.Id)" class="btn btn-default btn-sm">
                                        <span class="fa fa-exclamation-triangle" style="color: #f57f20; opacity: 0.7;"></span>
                                    </button>
                                </div>
                                <div ng-if="row.entity.EDIStatusIndicator === 2">
                                    <button type="button" ng-click="grid.appScope.consignmentListCtrl.openEDIPopup(row.entity.Id)" class="btn btn-default btn-sm">
                                        <span class="fa fa-refresh" ></span>
                                    </button>
                                </div>  
                                <div ng-if="row.entity.EDIStatusIndicator === 1">
                                    <button type="button" ng-click="grid.appScope.manifestUpdateCtrl.openEDIPopup(row.entity.Id)" class="btn btn-default btn-sm">
                                        <span class="fa fa-check-circle" style="color: #bcd856;"></span>
                                    </button>
                                </div>
                            </center>
                        </div>
                    `,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 45,
                }, {
                    name: "WAYBILL",
                    displayName: "Consignment Number",
                    field: "WaybillNumber",
                    width: 150,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                        </div>`
                }, {
                    name: "REFNUM",
                    displayName: "Reference Number",
                    field: "ReferenceNumber",
                    width: 150,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                        </div>`,
                }, {
                    name: "LOADLOC",
                    displayName: "Loading Location",
                    field: "LoadingLocation",
                    width: 200,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                        </div>`
                }, {
                    name: "UNLOADLOC",
                    displayName: "Unloading Location",
                    field: "UnloadingLocation",
                    width: 200,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                        </div>`
                }, {
                    name: "SHIPPER",
                    displayName: "Shipper",
                    field: "Shipper",
                    width: 200,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                        </div>`
                }, {
                    name: "CONSIGNEE",
                    displayName: "Consignee",
                    field: "Consignee",
                    width: 200,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                        </div>`
                }, {
                    name: "STATUS",
                    displayName: "Status",
                    field: "Status",
                    width: 200,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                }, {
                    name: 'CargoStatus',
                    displayName: 'Cargo Status',
                    field: 'CargoStatusIndicatorDropDown',
                    enableCellEditOnFocus: true,
                    cellEditableCondition: () => { return !this.manifest.IsSARSEDI && this.manifest.LockdownType < 5},
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Description}}</div>`,
                    editableCellTemplate: `
                            <form name="inputForm">
                                <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.manifestUpdateCtrl.loadCargoStatusIndicators()"></gts-dropdown>
                            </form>`,
                    enableSorting: false,
                    width: 200
                }
            
            ]
            };

            openEDIPopup(conId: number) {
                //Open Popup.
                return this.$uibModal.open({
                    animation: true,
                    template: `<div class="modal-content">
                                <div class="modal-header">
                                    <h3 class="modal-title" id="modal-title">Consignment EDI Status</h3>
                                </div>
                                <div class="modal-body" id="modal-body">
                                    <div class="vertical-align">
                                        <div class="col-md-12">
                                            <div id="gridTest" ui-grid="gvwConsignmentCustomsSummary" ui-grid-resize-columns ui-grid-cellNav class="gridSmallScreen"
                                                    ng-if="gvwConsignmentCustomsSummary">
                                                    <div layout="column" layout-align="center center" class="grid-msg-overlay" ng-if="IsLoading">
                                                        <div class="loadingGrid" ng-show="IsLoading">
                                                            <i class="fa fa-spinner fa-spin"></i>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-primary" type="button" ng-click="ok()">OK</button>
                                </div>
                            </div>`,
                    controller: ($scope, $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, consignmentService: interfaces.consignment.IConsignmentService) => {

                        $scope.ok = () => {
                            $uibModalInstance.dismiss(false);
                        };

                        $scope.gvwConsignmentCustomsSummary = {
                            data: [],
                            enableFiltering: false,
                            useExternalFiltering: false,
                            enableCellEdit: false,
                            useExternalSorting: false,
                            multiSelect: false,
                            enableColumnResizing: true,
                            enableFullRowSelection: false,
                            useExternalPagination: true,
                            enableRowHeaderSelection: false,
                            enableHorizontalScrollbar: 2,
                            enableVerticalScrollbar: 1,
                            onRegisterApi: (gridApi) => {
                                $scope.IsLoading = true;

                                consignmentService.GetConsignmentCustomsSummary(conId).query((result: interfaces.consignment.IConsignmentCustomsSummary) => {
                                    $scope.gvwConsignmentCustomsSummary.data = result;
                                    $scope.IsLoading = false;
                                }, (errorResponse) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                    $scope.IsLoading = false;
                                });
                            },
                            columnDefs: [{
                                name: "DeclarationCountry",
                                displayName: "Declaration Country",
                                field: "DeclarationCountry",
                                type: "string",
                                width: 135,
                            }, {
                                name: "CPC",
                                displayName: "CPC",
                                field: "CPC",
                                type: "string",
                                width: 75,
                            }, {
                                name: "MRN",
                                displayName: "MRN Number",
                                field: "MRN",
                                type: "string",
                                cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                                width: 145,
                            }, {
                                name: "CustomsStatus",
                                displayName: "Customs Status",
                                field: "CustomsStatus",
                                cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                                type: "string",
                                width: 132,
                            }, {
                                name: "CustomsStatusDate",
                                displayName: "Customs Status Date",
                                field: "CustomsStatusDate",
                                type: "string",
                                cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                                width: 147,
                            }, {
                                name: "CustomsMessage",
                                displayName: "Customs Message",
                                field: "CustomsMessage",
                                type: "string",
                                width: 135,
                                cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                            }, {
                                name: "Version",
                                displayName: "Version",
                                field: "Version",
                                width: 65,
                            }, {
                                name: "Function",
                                displayName: "Function",
                                field: "Function",
                                cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                                type: "string",
                                width: 75,
                            }, {
                                name: "AssessmentDate",
                                displayName: "Assessment Date",
                                field: "AssessmentDate",
                                type: "string",
                                cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD HH:mm:ss"}}</div>`,
                                width: 135,
                            },]
                        };
                    },
                    size: "xlg",
                    resolve: {
                    }
                }).result;
            }

            editConsignment(conId: number, isInbound: boolean) {
                if (isInbound) {
                    this.$state.go("auth.Manifests.Update.ConsignmentImport", { conId: conId });
                } else {
                    this.$state.go("auth.Manifests.Update.ConsignmentExport", { conId: conId });
                }
            }

            addNewConsignment() {
                if (this.manifest.IsInbound) {
                    this.$state.go("auth.Manifests.Update.CreateConsignmentImport");
                } else {
                    this.$state.go("auth.Manifests.Update.CreateConsignmentExport");
                }
            }

            removeConsignmentFromManifest() {
                if (this.apiConsignments && this.apiConsignments.selection.getSelectedRows().length > 0) {
                    var selected = this.apiConsignments.selection.getSelectedRows()[0];

                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'update'
                    }, () => {
                        return this.consignmentService.removeConsignmentFromManifest(selected.Id, this.manifest.Id).delete((result: interfaces.applicationcore.IMessageHandler) => {
                            this.generalService.displayMessageHandler(result);

                            if (!result.HasErrorMessage) {
                                this.$timeout(() => {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'update'
                                    }, () => {
                                        return this.loadManifest();
                                    });
                                });
                            }
                        }, (failureData) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                            }).$promise;
                    });

                } else {
                    this.generalService.displayMessage("Please select a consignment", Enum.EnumMessageType.Warning);
                }
            }

            printConsignmentDocuments() {
                if (this.apiConsignments && this.apiConsignments.selection.getSelectedRows().length > 0) {

                    var selected = this.apiConsignments.selection.getSelectedRows()[0];

                    if (selected.IsInbound) {
                        this.$state.go("auth.Manifests.Update.ConsignmentImport.Documents", { conId: selected.Id });
                    } else {
                        this.$state.go("auth.Manifests.Update.ConsignmentExport.Documents", { conId: selected.Id });
                    }

                } else {
                    this.generalService.displayMessage("Please select a consignment", Enum.EnumMessageType.Warning);
                }
            }

            bulkUpdateConsignment() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'update'
                }, () => {
                    return this.menuService.getGTSConnectMenuItem(569).get((result: interfaces.applicationcore.IMenu) => {
                        if (result.url) {
                            result.url += "?Id=" + this.manifest.Id;
                            var url = this.$state.href("auth.IFrame", { url: result.url });
                            window.open(url, '_blank');
                        } else {
                            this.generalService.displayMessage("You don't have rights for this page.", Enum.EnumMessageType.Error);
                        }
                    }).$promise;
                });
            }

            combineManifest() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'update'
                }, () => {
                    return this.SaveManifest().then((result) => {
                        this.generalService.displayMessageHandler(result);

                        if (!result.HasErrorMessage) {
                            this.showCombineModal();
                        }
                    });
                });
            }

            showCombineModal() {

                var modalInstance = this.$uibModal.open({
                    animation: true,
                    size: 'lg',
                    template: `
                    <div class="modal-header">
                        <strong class="modal-title">Combine Manifest with</strong>
                    </div>
                    <div class="modal-body">
                        <gts-message-display messages="Messages"></gts-message-display>
                        <div bs-loading-overlay bs-loading-overlay-reference-id="manifest.combine">
                            <div class="gts-padding">
                                <div ui-grid="gvwManifests" ui-grid-resize-columns ui-grid-selection class="gridSmallScreen">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="pull-right">
                            <button type="button" class="btn btn-primary" ng-click="Ok()">Ok</button>
                            <button type="button" class="btn btn-info" ng-click="Close()">Close</button>
                        </div>
                    </div>`,
                    controller: ['$uibModalInstance', '$scope', "bsLoadingOverlayService", "generalService",
                        ($modalInstance: ng.ui.bootstrap.IModalInstanceService, $scope: IManifestCombineScope, bsLoadingOverlayService: interfaces.applicationcore.IbsLoadingOverlayService,
                        generalService: interfaces.applicationcore.IGeneralService) => {

                        $scope.gvwManifests = {
                            data: [],
                            enableFiltering: true,
                            useExternalFiltering: true,
                            enableCellEdit: false,
                            useExternalSorting: true,
                            multiSelect: true,
                            enableColumnResizing: true,
                            enableFullRowSelection: true,
                            useExternalPagination: true,
                            enableRowHeaderSelection: false,
                            enableHorizontalScrollbar: 2,
                            rowEditWaitInterval: -1,
                            showGridFooter: true,
                            onRegisterApi: (gridApi) => {
                                $scope.apiManifests = gridApi;

                                bsLoadingOverlayService.wrap({
                                    referenceId: 'manifest.combine'
                                }, () => {
                                    return this.manifestService.getManifestsForCombiningById(this.manifest.Id).query((result) => {
                                        $scope.gvwManifests.data = result;
                                    }, (failureData) => {
                                        generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data, $scope);
                                        }).$promise;
                                    });
                            },
                            columnDefs: [{
                                name: "ENTITY",
                                displayName: "Entity",
                                field: "EntityCode",
                                width: 100,
                                enableCellEdit: false,
                                enableCellEditOnFocus: false,
                                filterHeaderTemplate: `
                                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                                        <div class="input-group-sm">
                                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                                    </div>`
                            },{
                                name: "MANNUM",
                                displayName: "Manifest Number",
                                field: "ManifestNumber",
                                width: 150,
                                enableCellEdit: false,
                                enableCellEditOnFocus: false,
                                filterHeaderTemplate: `
                                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                                        <div class="input-group-sm">
                                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                                    </div>`
                                }, {
                                    name: "MANDATE",
                                    displayName: "Date",
                                    field: "Date",
                                    width: 100,
                                    cellFilter: "momentDateFilter: 'YYYY/MM/DD'",
                                    enableCellEdit: false,
                                    enableCellEditOnFocus: false,
                                    filterHeaderTemplate: `
                                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                                        <div class="input-group-sm">
                                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                                    </div>`
                            }, {
                                name: "REFNUM",
                                displayName: "Route",
                                field: "RouteCodeCode",
                                width: 150,
                                enableCellEdit: false,
                                enableCellEditOnFocus: false,
                                filterHeaderTemplate: `
                                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                                        <div class="input-group-sm">
                                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                                    </div>`
                                }, {
                                    name: "TRANS",
                                    displayName: "Transport Mode",
                                    field: "TransportModeDescription",
                                    width: 100,
                                    enableCellEdit: false,
                                    enableCellEditOnFocus: false,
                                    filterHeaderTemplate: `
                                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                                        <div class="input-group-sm">
                                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                                    </div>`
                            }, {
                                name: "LOADCNT",
                                displayName: "Loading Country",
                                field: "LoadingCountryCode",
                                width: 150,
                                enableCellEdit: false,
                                enableCellEditOnFocus: false,
                                filterHeaderTemplate: `
                                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                                        <div class="input-group-sm">
                                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                                    </div>`
                                }, {
                                    name: "UNLOADCNT",
                                    displayName: "Unloading Country",
                                    field: "DestinationCountryCode",
                                    width: 150,
                                    enableCellEdit: false,
                                    enableCellEditOnFocus: false,
                                    filterHeaderTemplate: `
                                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                                        <div class="input-group-sm">
                                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                                    </div>`
                            }, {
                                name: "STATUS",
                                displayName: "Status",
                                field: "ManifestStatusDescription",
                                width: 100,
                                enableCellEdit: false,
                                enableCellEditOnFocus: false,
                                filterHeaderTemplate: `
                                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                                        <div class="input-group-sm">
                                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                                    </div>`
                            }]
                        };

                        $scope.Close = () => {
                            $modalInstance.dismiss("Cancel");
                        };

                        $scope.Ok = () => {
                            if ($scope.apiManifests && $scope.apiManifests.selection.getSelectedRows().length > 0) {
                                var ids = _.map($scope.apiManifests.selection.getSelectedRows(), (o) => {
                                    return o.ManifestId;
                                });

                                $modalInstance.close(ids);
                            } else {
                                generalService.displayMessage("Please select a manifest", Enum.EnumMessageType.Warning, $scope);
                            }
                        };
                    }],
                    resolve: {

                    }
                });

                modalInstance.result.then((result: Array<number>) => {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'update'
                    }, () => {
                        return this.CombineManifest(result).then((result) => {
                            this.generalService.displayMessageHandler(result);

                            if (!result.HasErrorMessage) {
                                this.$timeout(() => {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'update'
                                    }, () => {
                                        return this.loadManifest();
                                    });
                                });
                            }
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                            this.$anchorScroll("topAnchor");
                        });
                    });
                }, () => {

                });
            }

            CombineManifest(selectedIds: Array<number>) {
                return this.manifestService.combineMaifestsProcessing(this.manifest.Id, selectedIds).save((result: interfaces.applicationcore.IMessageHandler) => {
                    this.$anchorScroll("topAnchor");
                }, (failureData) => {
                    this.$anchorScroll("topAnchor");
                }).$promise;
            }

            addExistingConsignment() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'update'
                }, () => {
                    return this.SaveManifest().then((result) => {
                        this.generalService.displayMessageHandler(result);

                        if (!result.HasErrorMessage) {
                            this.showAddExistingModal();
                        }
                    });
                });
            }

            showAddExistingModal() {
                var modalInstance = this.$uibModal.open({
                    animation: true,
                    size: 'lg',
                    template: `
                    <div class="modal-header">
                        <strong class="modal-title">Add Existing Consignment</strong>
                    </div>
                    <div class="modal-body">
                        <gts-message-display messages="Messages"></gts-message-display>
                        <div bs-loading-overlay bs-loading-overlay-reference-id="manifest.add.existing.con">
                            <div class="gts-padding">
                                <div class="pull-right vertical-align">
                                    <label class="inline">Show All for Country of Destination</label>
                                    <p>
                                        <input type="checkbox" ng-model="showAll" ng-change="checkBoxChanged()">
                                    </p>
                                </div>
                                <br />
                                <br />
                                <div ui-grid="gvwConsignments" ng-if="gvwConsignments" ui-grid-pagination ui-grid-resize-columns ui-grid-selection class="gridSmallScreen">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="pull-right">
                            <button type="button" class="btn btn-primary" ng-click="Ok()">OK</button>
                            <button type="button" class="btn btn-info" ng-click="Close()">Close</button>
                        </div>
                    </div>`,
                    controller: ['$uibModalInstance', '$scope', "bsLoadingOverlayService", "generalService",
                        ($modalInstance: ng.ui.bootstrap.IModalInstanceService, $scope: IExistingConsignmentScope, bsLoadingOverlayService: interfaces.applicationcore.IbsLoadingOverlayService,
                            generalService: interfaces.applicationcore.IGeneralService) => {

                            $scope.gvwConsignments = {
                                data: [],
                                enableFiltering: true,
                                useExternalFiltering: true,
                                enableCellEdit: false,
                                useExternalSorting: true,
                                enablePagination: true,
                                paginationPageSizes: [10, 25, 50, 100],
                                paginationPageSize: 10,
                                multiSelect: true,
                                enableColumnResizing: true,
                                enableFullRowSelection: true,
                                useExternalPagination: true,
                                enableRowHeaderSelection: false,
                                enableHorizontalScrollbar: 2,
                                rowEditWaitInterval: -1,
                                showGridFooter: true,
                                onRegisterApi: (gridApi) => {
                                    $scope.apiConsignments = gridApi;

                                    $scope.searchObject = {
                                        filters: [],
                                        sorts: []
                                    }

                                    $scope.pageCount = 10;
                                    $scope.pageNumber = 1;
                                    $scope.showAll = false;
                                    $scope.selectedIds = [];

                                    $scope.apiConsignments.selection.on.rowSelectionChanged($scope, (row, event?: ng.IAngularEvent) => {
                                        if (row.isSelected) {
                                            $scope.selectedIds.push(row.entity.Id);
                                        } else {
                                            _.remove($scope.selectedIds, (o) => {
                                                return o === row.entity.Id;
                                            });
                                        }
                                    });

                                    $scope.apiConsignments.core.on.filterChanged($scope, () => {
                                        bsLoadingOverlayService.wrap({
                                            referenceId: 'manifest.add.existing.con'
                                        }, () => {
                                            $scope.apiConsignments.selection.clearSelectedRows();
                                            return this.loadExistingConsignments($scope);
                                        });
                                    });

                                    $scope.apiConsignments.pagination.on.paginationChanged($scope, (newPage, pageSize) => {
                                        $scope.pageNumber = newPage;
                                        $scope.pageCount = pageSize;
                                        bsLoadingOverlayService.wrap({
                                            referenceId: 'manifest.add.existing.con'
                                        }, () => {

                                            return this.loadExistingConsignments($scope);
                                        });
                                    });

                                    $scope.apiConsignments.core.on.sortChanged($scope, () => {
                                        bsLoadingOverlayService.wrap({
                                            referenceId: 'manifest.add.existing.con'
                                        }, () => {
                                            return this.loadExistingConsignments($scope);
                                        });
                                    });

                                    bsLoadingOverlayService.wrap({
                                        referenceId: 'manifest.add.existing.con'
                                    }, () => {
                                        return this.loadExistingConsignments($scope);
                                    });
                                },
                                columnDefs: [ {
                                    name: "EDIStatus",
                                    displayName: "EDI",
                                    field: "EDIStatus",
                                    enableFiltering: false,
                                    cellTemplate: `
                                        <div class="GridButton">
                                            <center>
                                                <div ng-if="row.entity.EDIStatusIndicator === 0">
                                                    <button type="button" ng-click="grid.appScope.openEDIPopup(row.entity.Id)" class="btn btn-default btn-sm">
                                                        <span class="fa fa-edit"></span>
                                                    </button>
                                                </div>
                                                <div ng-if="row.entity.EDIStatusIndicator === 4">
                                                    <button type="button" ng-click="grid.appScope.openEDIPopup(row.entity.Id)" class="btn btn-default btn-sm">
                                                        <span class="fa fa-times" style="color: #cb2330;"></span>
                                                    </button>
                                                </div>
                                                <div ng-if="row.entity.EDIStatusIndicator === 3">
                                                    <button type="button" ng-click="grid.appScope.openEDIPopup(row.entity.Id)" class="btn btn-default btn-sm">
                                                        <span class="fa fa-exclamation-triangle" style="color: #f57f20; opacity: 0.7;"></span>
                                                    </button>
                                                </div>
                                                <div ng-if="row.entity.EDIStatusIndicator === 2">
                                                    <button type="button" ng-click="grid.appScope.consignmentListCtrl.openEDIPopup(row.entity.Id)" class="btn btn-default btn-sm">
                                                        <span class="fa fa-refresh" ></span>
                                                    </button>
                                                </div>                                                  
                                                <div ng-if="row.entity.EDIStatusIndicator === 1">
                                                    <button type="button" ng-click="grid.appScope.openEDIPopup(row.entity.Id)" class="btn btn-default btn-sm">
                                                        <span class="fa fa-check-circle" style="color: #bcd856;"></span>
                                                    </button>
                                                </div>
                                            </center>
                                        </div>
                                    `,
                                    enableSorting: false,
                                    enableColumnMenu: false,
                                    width: 45,
                                }, {
                                    name: "CONNUM",
                                    displayName: "Consignment Number",
                                    field: "WaybillNumber",
                                    width: 200,
                                    enableCellEdit: false,
                                    enableCellEditOnFocus: false,
                                    filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                        </div>`,
                                    sort: { direction: 'asc', priority: 1 }
                                }, {
                                    name: "CONDATE",
                                    displayName: "Consignment Date",
                                    field: "WaybillDate",
                                    width: 200,
                                    cellFilter: "momentDateFilter: 'YYYY/MM/DD'",
                                    enableCellEdit: false,
                                    enableCellEditOnFocus: false,
                                    filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                            <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="orderDate"></gts-date>
                                                    
                                        </div>`,
                                    sort: { direction: 'desc', priority: 0 }
                                    }, {
                                        name: "REFNUM",
                                        displayName: "Reference Number",
                                        field: "ReferenceNumber",
                                        width: 200,
                                        enableCellEdit: false,
                                        enableCellEditOnFocus: false,
                                        filterHeaderTemplate: `
                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                        <div class="input-group-sm">
                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                    </div>`,
                                        cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                                    }, {
                                        name: "CONTYPE",
                                        displayName: "Consignment Type",
                                        field: "ConsignmentType",
                                        width: 200,
                                        enableCellEdit: false,
                                        enableCellEditOnFocus: false,
                                        filterHeaderTemplate: `
                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                        <div class="input-group-sm">
                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                    </div>`,
                                        cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                                    }, {
                                        name: "LOADCNT",
                                        displayName: "Loading Country",
                                        field: "LoadingCountry",
                                        width: 150,
                                        enableCellEdit: false,
                                        enableCellEditOnFocus: false,
                                        filterHeaderTemplate: `
                                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                                        <div class="input-group-sm">
                                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                                    </div>`
                                    }, {
                                        name: "UNLOADCNT",
                                        displayName: "Unloading Country",
                                        field: "UnloadingCountry",
                                        width: 150,
                                        enableCellEdit: false,
                                        enableCellEditOnFocus: false,
                                        filterHeaderTemplate: `
                                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                                        <div class="input-group-sm">
                                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                                    </div>`
                                    }, {
                                        name: "SHIPPER",
                                        displayName: "Shipper",
                                        field: "Shipper",
                                        width: 200,
                                        enableCellEdit: false,
                                        enableCellEditOnFocus: false,
                                        filterHeaderTemplate: `
                                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                                        <div class="input-group-sm">
                                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                                    </div>`
                                    }, {
                                        name: "CONSIGNEE",
                                        displayName: "Consignee",
                                        field: "Consignee",
                                        width: 200,
                                        enableCellEdit: false,
                                        enableCellEditOnFocus: false,
                                        filterHeaderTemplate: `
                                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                                        <div class="input-group-sm">
                                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                                    </div>`
                                    }, {
                                        name: "STATUS",
                                        displayName: "Status",
                                        field: "Status",
                                        width: 200,
                                        enableCellEdit: false,
                                        enableCellEditOnFocus: false,
                                        filterHeaderTemplate: `
                                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                                        <div class="input-group-sm">
                                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                                    </div>`
                                    }
                                    
                                
                                ]
                            };

                            $scope.openEDIPopup = (id: number) => {
                                this.openEDIPopup(id);
                            }

                            $scope.Close = () => {
                                $modalInstance.dismiss("Cancel");
                            };

                            $scope.Ok = () => {
                                $modalInstance.close($scope.selectedIds);
                            };

                            $scope.checkBoxChanged = () => {
                                bsLoadingOverlayService.wrap({
                                    referenceId: 'manifest.add.existing.con'
                                }, () => {
                                    $scope.pageNumber = 1;
                                    $scope.selectedIds = [];
                                    $scope.apiConsignments.selection.clearSelectedRows();
                                    return this.loadExistingConsignments($scope);
                                });
                            }
                        }],
                    resolve: {

                    }
                });

                modalInstance.result.then((result: Array<number>) => {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'update'
                    }, () => {
                        return this.saveConsignmentForManifest(result).then((result) => {
                            this.generalService.displayMessageHandler(result);

                            if (!result.HasErrorMessage) {
                                this.$timeout(() => {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'update'
                                    }, () => {
                                        return this.loadManifest();
                                    });
                                });
                            }
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        });
                    });
                }, () => {

                });
            }

            loadExistingConsignments(scope: IExistingConsignmentScope) {
                if (scope.apiConsignments) {

                    var grid = scope.apiConsignments.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            scope.searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            scope.searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }
                    });
                }

                return this.consignmentService.getConsignmentForManifest(scope.searchObject, scope.pageCount, scope.pageNumber,
                    this.manifest.OwnerEntityId, this.manifest.Route.Id, scope.showAll, this.manifest.DestinationCountry.Id).query((result: Array<interfaces.consignment.IManifestConsignmentDisplay>) => {
                        scope.gvwConsignments.data = result;

                        if (result.length > 0) {
                            scope.gvwConsignments.totalItems = result[0].NumRecs;
                        } else {
                            scope.gvwConsignments.totalItems = 0;
                        }

                        var ids = scope.selectedIds;
                        scope.apiConsignments.selection.clearSelectedRows();

                        if (ids && ids.length > 0) {
                            _.forEach(ids, (o) => {
                                var selectItem = _.find(result, (n) => { return n.Id === o; });

                                if (selectItem) {
                                    this.$timeout(() => {
                                        scope.apiConsignments.selection.unSelectRow(selectItem);
                                        scope.apiConsignments.selection.selectRow(selectItem);
                                    });

                                }
                            });
                        }

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data, scope);
                    }).$promise;
            }

            saveConsignmentForManifest(consignmentIds: Array<number>) {
                return this.consignmentService.saveConsignmentForManifest(this.manifest.Id, consignmentIds).save((result: interfaces.applicationcore.IMessageHandler) => {
                    this.$anchorScroll("topAnchor");
                }, (failureData) => {
                    this.$anchorScroll("topAnchor");
                }).$promise;
            }

            mapDirtyRows() {
                if (this.apiCrews) {
                    this.manifest.ManifestCrewList = _.map(this.apiCrews.rowEdit.getDirtyRows(), (o) => { return o.entity; });
                } else {
                    this.manifest.ManifestCrewList = [];
                }
                if (this.apiSundryItem) {
                    this.manifest.SundryItemList = _.map(this.apiSundryItem.rowEdit.getDirtyRows(), (o) => { return o.entity; });
                } else {
                    this.manifest.SundryItemList = [];
                }
                if (this.apiContainersList) {
                    this.manifest.ContainersList = _.map(this.apiContainersList.rowEdit.getDirtyRows(), (o) => { return o.entity; });
                } else {
                    this.manifest.ContainersList = [];
                }

                if (this.apiConsignments) {
                    this.manifest.ConsignmentList = _.map(this.apiConsignments.rowEdit.getDirtyRows(), (o) => { return o.entity; });
                } else {
                    this.manifest.ConsignmentList = [];
                }
            }

            cleanDirtyRows() {
                if (this.apiCrews) {
                    this.apiCrews.rowEdit.setRowsClean(this.manifest.ManifestCrewList);
                }
                if (this.apiSundryItem) {
                    this.apiSundryItem.rowEdit.setRowsClean(this.manifest.SundryItemList);
                }
                if (this.apiContainersList) {
                    this.apiContainersList.rowEdit.setRowsClean(this.manifest.ContainersList);
                }

                if (this.apiConsignments) {
                    this.apiConsignments.rowEdit.setRowsClean(this.manifest.ConsignmentList);
                }
            }

            generateSundryItems_click() {

                this.$timeout(() => {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'update'
                    }, () => {
                        return this.SaveManifestAndGenerateSundryItems().then((result) => {

                            this.generalService.displayMessageHandler(result);

                            if (!result.HasErrorMessage) {
                                this.$timeout(() => {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'update'
                                    }, () => {
                                        return this.loadManifest();
                                    });
                                });
                            }
                        });
                    });
                });
            }

            apportionSundryItems_click() {

                this.$timeout(() => {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'update'
                    }, () => {
                        return this.SaveManifestAndApportionSundryItems().then((result) => {

                            this.generalService.displayMessageHandler(result);

                            if (!result.HasErrorMessage) {
                                this.$timeout(() => {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'update'
                                    }, () => {
                                        return this.loadManifest();
                                    });
                                });
                            }
                        });
                    });
                });
            }
            apiContainersList: uiGrid.IGridApi;
            gvwContainers: uiGrid.IGridOptions = {
                data: <Array<interfaces.logistics.IContainerDisplay>>[],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                cellEditableCondition: () => { return this.manifest.LockdownType < 5 },
                showGridFooter: true,
                onRegisterApi: (gridApi) => { this.registerContainersGridApi(gridApi); },
                columnDefs: [{
                    name: "EDIT",
                    displayName: "",
                    field: "Id",
                    cellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" ng-click="grid.appScope.manifestUpdateCtrl.removeInlineContainers(row.entity)" class="btn btn-warning btn-sm" ng-disabled="grid.appScope.manifestUpdateCtrl.manifest.LockdownType > 4">
                                Delete
                            </button>
                            <button type="button" ui-sref="auth.Manifests.Update.Container({ contId: MODEL_COL_FIELD })" class="btn btn-default btn-sm" ng-disabled="grid.appScope.manifestUpdateCtrl.manifest.LockdownType > 4">
                                <span class="fa fa-pencil"></span>
                            </button>
                        </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    enableCellEdit: false,
                    width: 92,
                    enableCellEditOnFocus: false,
                    headerCellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.manifestUpdateCtrl.addInlineContainers()" ng-disabled="grid.appScope.manifestUpdateCtrl.manifest.LockdownType > 4">
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`
                }, {
                    name: "CONTAINERNUMBER",
                    displayName: "Container Number",
                    field: "Number",
                    type: "string",
                    enableCellEdit: true,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    editableCellTemplate: `
                        <form name = "inputForm">
                            <p class="input-group-sm">
                                <input type="text" name="Number-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" class="form-control" ui-grid-editor step = "0.01" />
                            </p>
                        </form>`,
                    enableCellEditOnFocus: true
                }, {
                    name: "CONTAINERISO",
                    displayName: "Container Size",
                    field: "ContainerISOCode",
                    type: "string",
                    enableCellEdit: true,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `
                            <form name="inputForm">
                                <gts-dropdown ui-select-wrap name="ContainerISOCode-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.manifestUpdateCtrl.loadContainerISOs()"></gts-dropdown>
                            </form>`,
                    enableCellEditOnFocus: true
                }, {
                    name: "GROSSMASS",
                    displayName: "Gross Mass",
                    field: "GrossMass",
                    cellClass: 'text-right',
                    type: "number",
                    enableCellEdit: true,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    editableCellTemplate: `
                        <form name = "inputForm">
                            <p class="input-group-sm">
                                <input type="text" name="GrossMass-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" class="form-control" ui-grid-editor step = "0.01" />
                            </p>
                        </form>`,
                    enableCellEditOnFocus: true
                }]
            };

            registerContainersGridApi(gridApi: uiGrid.IGridApi) {
                this.apiContainersList = gridApi;

                this.apiContainersList.cellNav.on.navigate(this.$scope, (gridSelection) => {
                    this.apiContainersList.selection.selectRow(gridSelection.row.entity);
                });
            }

            //Add and remove in line Contaainer
            addInlineContainers() {

                (<Array<any>>(this.gvwContainers.data)).splice(0, 0, {});

                this.$timeout(() => {
                    if (this.apiContainersList) {
                        this.apiContainersList.core.notifyDataChange(this.uiGridConstants.dataChange.ALL)
                    }
                });
            }

            removeInlineContainers(entity: interfaces.logistics.IContainer) {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'update'
                }, () => {
                    return this.containerService.delete(null, this.manifest.Id, entity.Id).delete((result) => {
                        this.$timeout(() => {
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'update'
                            }, () => {
                                return this.loadManifest();
                            });
                        });
                    }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                });
            }

            //Load Container ISO.
            loadContainerISOs(searchText: string) {
                return this.containerISOService.getContainerISODropdown(searchText).query(
                    () => {

                    }, (failureData) => {
                    }).$promise;
            }

            SaveManifestAndGenerateSundryItems() {

                this.mapDirtyRows();

                return this.manifestService.generateManifestSundryItem().save((this.manifest), (result: interfaces.applicationcore.IMessageHandler) => {

                    if (!result.HasErrorMessage) {
                        this.cleanDirtyRows();
                    }

                    this.$anchorScroll("topAnchor");
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    this.$anchorScroll("topAnchor");
                }).$promise;
            }

            SaveManifestAndApportionSundryItems() {

                this.mapDirtyRows();

                return this.manifestService.apportionManifestSundryItemsToConsignments().save((this.manifest), (result: interfaces.applicationcore.IMessageHandler) => {

                    if (!result.HasErrorMessage) {
                        this.cleanDirtyRows();
                    }

                    this.$anchorScroll("topAnchor");
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    this.$anchorScroll("topAnchor");
                }).$promise;
            }

            CreateNewVersion() {

                this.showCreateNewVersionModal(this.manifest).then((data:interfaces.manifest.IManifestChangeCancel) => {
                    if (data) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'update'
                        }, () => {
                            return this.manifestService.createNewManifestVersion(this.manifest.Id, data.reason,data.changeReasonId).save((result) => {
                                this.$anchorScroll("topAnchor");
                                this.generalService.displayMessageHandler(result);

                                if (!result.HasErrorMessage) {
                                    this.$timeout(() => {
                                        this.bsLoadingOverlayService.wrap({
                                            referenceId: 'update'
                                        }, () => {
                                            return this.loadManifest();
                                        });
                                    });
                                }
                            }, (errorResponse) => {
                                this.$anchorScroll("topAnchor");
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
                    }
                }, () => {

                });

            }

            showCreateNewVersionModal(manifest: interfaces.manifest.IManifest) {
                return this.$uibModal.open({
                    animation: true,
                    template: `<div class="modal-content">
                                <div class="modal-header">
                                    <h3 class="modal-title" id="modal-title">Create New Version</h3>
                                </div>
                                <div class="modal-body" id="modal-body">
                                    <div class="row">
                                        <div class="vertical-align">
                                            <div class="col-md-4">
                                                <opt></opt>
                                                <label>Manifest Number</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                    {{manifestNumber}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="vertical-align">
                                            <div class="col-md-4">
                                                <opt></opt>
                                                <label>Manifest Date</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                    {{manifestDate | momentDateFilter: 'YYYY/MM/DD HH:mm'}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="vertical-align">
                                            <div class="col-md-4">
                                                <opt></opt>
                                                <label>Version</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                    {{version}}
                                                </p>
                                            </div>
                                        </div>                                        
                                        <div class="vertical-align">
                                            <div class="col-md-4">
                                                <opt></opt>
                                                <label>Alternate Code</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                    {{alternateReference}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="vertical-align">
                                            <div class="col-md-4">
                                                <opt></opt>
                                                <label>Route</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                    {{route}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="vertical-align">
                                            <div class="col-md-4">
                                                <opt></opt>
                                                <label>Transport Mode</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                    {{transportMode}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="vertical-align">
                                            <div class="col-md-4">
                                                <opt ng-if="!EnforceVersionCancelReason"></opt>
                                                <req ng-if="EnforceVersionCancelReason"></req>
                                                <label>Amendment Reason</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                <gts-dropdown ng-model="versionCancelReason" load-data="getVersionCancelReasons()"></gts-dropdown>
                                                </p>
                                            </div>
                                        </div>                                        
                                        <div class="vertical-align">
                                            <div class="col-md-4">
                                                <req></req>
                                                <label>Reason</label>
                                            </div>
                                        </div>
                                        <div class="vertical-align">
                                            <div class="col-md-12">
                                                <textarea rows="3" name="reason" ng-model="reason" class="form-control"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-primary" type="button" ng-click="ok()" ng-disabled="!reason || (EnforceVersionCancelReason && !versionCancelReason)">OK</button>
                                    <button class="btn btn-info" type="button" ng-click="cancel()">Cancel</button>
                                </div>
                            </div>`,
                    controller: ($scope, $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, manifestTypeService: interfaces.manifest.IManifestTypeService) => {
                        $scope.manifestNumber = manifest.ManifestNumber;
                        $scope.manifestDate = manifest.Date;
                        $scope.alternateReference = manifest.AlternateReference;
                        $scope.route = manifest.Route.Display;
                        $scope.transportMode = manifest.TransportMode.Description;
                        $scope.version = manifest.Version;
                        $scope.entityId = manifest.OwnerEntityId;
                       
                        $scope.loadManifestTypeDefaults = () => {
                                this.manifestTypeService.getManifestTypeDefaults(manifest.ManifestTypeId).get().$promise.then((data) => {
                                    $scope.EnforceVersionCancelReason = data.EnforceVersionCancelReason;
                                }, (failureData) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                                });
                         }

                         $scope.loadManifestTypeDefaults();

                         $scope.getVersionCancelReasons = () => {
                            return this.manifestService.getVersionCancelReasons( $scope.entityId ).query((resultList) => {
                    
                            }, (failureData) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                            }).$promise;
                         }


                        $scope.ok = () => {
                            var changeReason : interfaces.manifest.IManifestChangeCancel = {
                                reason : $scope.reason,
                                changeReasonId: $scope.versionCancelReason?  $scope.versionCancelReason.Id:undefined
                            }
                            $uibModalInstance.close(changeReason);
                        };

                        $scope.cancel = () => {
                            $uibModalInstance.dismiss(false);
                        };
                    },
                    resolve: {
                    }
                }).result;
            }

            SaveAndValidateManifest() {

                this.mapDirtyRows();

                return this.manifestService.saveAndValidateEDI().save((this.manifest), (result: interfaces.applicationcore.IMessageHandler) => {

                    if (!result.HasErrorMessage) {
                        this.cleanDirtyRows();
                    }

                    this.$anchorScroll("topAnchor");
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    this.$anchorScroll("topAnchor");
                }).$promise;
            }

            RequestEDIResponseResendManifest() {
                return this.manifestService.requestEDIResponseResend().get({
                    ownerEntityId: this.manifest.OwnerEntityId,
                    manifestId: this.manifest.Id
                }, (result: interfaces.applicationcore.IMessageHandler) => {

                    if (!result.HasErrorMessage) {
                        this.$timeout(() => {
                            return this.loadManifest();
                        });
                    }

                    this.$anchorScroll("topAnchor");
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    this.$anchorScroll("topAnchor");
                }).$promise;
            }

            requestEDIResponseResend() {
                this.$timeout(() => {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'con.update.gsd'
                    }, () => {
                        return this.RequestEDIResponseResendManifest().then((result) => {

                            this.generalService.displayMessageHandler(result);

                            if (!result.HasErrorMessage) {
                                this.$timeout(() => {
                                    //this.bsLoadingOverlayService.wrap({
                                    //    referenceId: 'update'
                                    //}, () => {
                                    //    return this.loadGoodsDeclaration();
                                    //});
                                });
                            }
                        });
                    });
                });
            }

            loadCargoStatusIndicators() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.ManifestCargoStatusIndicator
                }, (resultList) => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadALCVarianceReason() {
                var deferred = this.$q.defer();

                if (!this.manifest) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.rejectionService.getDropdownListByOwnerEntityId(786, this.manifest.OwnerEntityId).query({

                }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                });

                return deferred.promise;
            }

            calculateALCVariance() {
                return this.manifestService.CalculateALCVariance(this.manifest.Id).get((result) => {
                    this.generalService.displayMessageHandler(result);
                    if (!result.HasErrorMessage) {
                        this.$timeout(() => {
                            return this.loadManifest();
                        });
                    }
                });
            }
            loadALCSignOffStatuses() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: 149
                }, () => {

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }
            SaveAndSendManifest() {

                this.mapDirtyRows();

                return this.manifestService.saveAndSendEDI().save((this.manifest), (result: interfaces.applicationcore.IMessageHandler) => {

                    if (!result.HasErrorMessage) {
                        this.cleanDirtyRows();
                    }

                    this.$anchorScroll("topAnchor");
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    this.$anchorScroll("topAnchor");
                }).$promise;
            }

            SaveManifest() {

                this.mapDirtyRows();

                return this.manifestService.save().save((this.manifest), (result: interfaces.applicationcore.IMessageHandler) => {

                    if (!result.HasErrorMessage) {
                        this.cleanDirtyRows();
                    }

                    this.$anchorScroll("topAnchor");
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    this.$anchorScroll("topAnchor");
                }).$promise;
            }

            //Submit/Update
            Save() {
                this.$timeout(() => {
                    //Call Update Function
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'update'
                    }, () => {
                        return this.SaveManifest().then((result) => {

                            this.generalService.displayMessageHandler(result);

                            if (!result.HasErrorMessage) {
                                this.$timeout(() => {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'update'
                                    }, () => {
                                        return this.loadManifest();
                                    });
                                });
                            }
                        });
                    });
                });
            }

            SaveClose() {
                this.$timeout(() => {
                    //Call Update Function
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'update'
                    }, () => {
                        return this.SaveManifest().then(() => {
                            //Call Close
                            this.Close();
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                            this.$anchorScroll("topAnchor");
                        });
                    });
                });
            }

            Close() {
                this.$state.go("^");
            }
        };

        angular.module("app").controller("manifestUpdateCtrl", controllers.manifest.manifestUpdateCtrl);
    }
}